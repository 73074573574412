<template>
    <div class="daily-bonus-item">
        <div
            class="daily-bonus-item__header"
            :class="[`daily-bonus-item__header--${collapsedClass}`]"
            @click="collapsed = !collapsed"
        >
            <div class="float-left">День #{{ data.Day }}</div>
            <i class="daily-bonus-item__collapse-button" />
            <ui-button
                color="transparent" size="small" class="daily-bonus-item__delete-button"
                @clicked="onDeleteClick"
            >
                Удалить
            </ui-button>
        </div>
        <div v-if="!collapsed" class="daily-bonus-item__settings">
            <div>
                <label class="my-0 text-left">Стандартный бонус</label>
                <b-dropdown :text="selectedStandardCurrencyName">
                    <b-dropdown-item
                        v-for="option of options" :key="option.value" @click="selectStandardCurrency"
                        :data-value="option.value"
                    >
                        {{ option.name }}
                    </b-dropdown-item>
                </b-dropdown>
                <input
                    type="number" class="form-control" v-model.number="rewardValue"
                    min="0" oninput="validity.valid||(value=0)"
                />
            </div>
            
            <div>
                <label class="my-0 text-left">Рекламный бонус</label>
                <b-dropdown :text="selectedAdsBonusCurrencyName">
                    <b-dropdown-item
                        v-for="option of options" :key="option.value" @click="selectAdsBonusCurrency"
                        :data-value="option.value"
                    >
                        {{ option.name }}
                    </b-dropdown-item>
                </b-dropdown>
                <input
                    type="number" class="form-control" v-model.number="adsValue"
                    min="0" oninput="validity.valid||(value=0)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        initialData: Object,
    },
    data: () => ({
        collapsed: true,
        standardCurrencyOptionId: 0,
        adsBonusCurrencyOptionId: 0,
        data: {},
        rewardValue: 0,
        adsValue: 0
    }),
    components: {
        UiButton: () => import('../ui/UiButton'),
    },
    computed: {
        ...mapGetters('firebase', ['currencies']),
        collapsedClass() {
            return this.collapsed ? 'collapsed' : 'expanded';
        },
        options() {
            return this.$store.getters['firebase/currenciesOptions'];
        },
        selectedStandardCurrencyName() {
            return this.$store.getters['firebase/getCurrencyName'](this.standardCurrencyOptionId);
        },
        selectedAdsBonusCurrencyName() {
            return this.$store.getters['firebase/getCurrencyName'](this.adsBonusCurrencyOptionId);
        }
    },
    created() {
        this.setData(this.initialData);
    },
    watch: {
        rewardValue(newValue, oldValue) {
            if (!oldValue || isNaN(oldValue))
                return;
            
            this.$emit('changed', this.data.Day, 'Reward', { id: this.standardCurrencyOptionId, value: newValue });
        },
        adsValue(newValue, oldValue) {
            if (!oldValue || isNaN(oldValue))
                return;
            
            this.$emit('changed', this.data.Day, 'AdsBonus', { id: this.adsBonusCurrencyOptionId, value: newValue });
        },
        standardCurrencyOptionId(newValue, oldValue) {
            if (!oldValue || isNaN(oldValue))
                return;
    
            this.$emit('changed', this.data.Day, 'Reward', { id: newValue, value: this.rewardValue });
        },
        adsBonusCurrencyOptionId(newValue, oldValue) {
            if (!oldValue || isNaN(oldValue))
                return;
    
            this.$emit('changed', this.data.Day, 'AdsBonus', { id: newValue, value: this.adsValue });
        }
    },
    methods: {
        setData(data) {
            this.data = data;
            
            this.rewardValue = data.Reward.value;
            this.standardCurrencyOptionId = data.Reward.id;
            
            this.adsValue = data.AdsBonus.value;
            this.adsBonusCurrencyOptionId = data.AdsBonus.id;
        },
        getData() {
            return this.data;
        },
        onDeleteClick() {
            this.$emit('deleted', this.data.Day);
        },
        selectStandardCurrency(event) {
            this.standardCurrencyOptionId = +event.target.dataset.value;
        },
        selectAdsBonusCurrency(event) {
            this.adsBonusCurrencyOptionId = +event.target.dataset.value;
        }
    }
};
</script>

<style lang="scss">
.daily-bonus-item {
    &__header {
        position: relative;
        width: 100%;
        height: 22px;
        
        .daily-bonus-item__collapse-button {
            float: left;
            margin-left: 4px;
            
            &:after {
                content: '';
                display: block;
                float: left;
            }
        }
        
        &--collapsed {
            .daily-bonus-item__collapse-button:after {
                content: '▼';
            }
        }
        
        &--expanded {
            padding-bottom: 36px;
            margin-bottom: 12px;
            border-bottom: 1px solid rgba(154, 170, 188, .5);
            
            .daily-bonus-item__collapse-button:after {
                content: '▲';
            }
        }
    }
    
    &__settings {
        display: flex;
        justify-content: flex-start;
        margin-top: 12px;
        
        div {
            display: flex;
            flex-direction: column;
            
            &:first-child {
                margin-right: 8px;
            }
            
            > * {
                margin: 4px 0;
            }
        }
    }
    
    &__delete-button {
        float: right;
    }
}
</style>
